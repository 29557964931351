import React from "react";
import * as styles from "./index.module.scss";

function ReadMoreButton({ type, color, children, size }) {
  const btnType =
    type === "primary"
      ? styles.primary
      : type === "secondary"
      ? styles.secondary
      : "";

  const btnColor = color === "white" ? styles.white : "";
  const btnSize = size === "large" ? styles.large : "";

  return (
    <button
      className={`${styles.button} ${btnType} ${btnColor} ${btnSize} relative flex justify-between py-3 px-8 text-left border-black border-t border-r border-b border cursor-pointer`}
    >
      <span className={`${styles.buttonText} title title-xxs self-center font-bold`}>
        {children}
      </span>
      <div className={`${styles.icon} self-center`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
        </svg>
      </div>
    </button>
  );
}

ReadMoreButton.defaultProps = {
  type: "primary",
  color: "black",
  size: ""
};

export default ReadMoreButton;
